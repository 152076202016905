import clgimage from './stjit23.jpeg';
import './Clgimage.css';

function Clgimage()
{
    return(
        <div className='clgimage'>
           <img src={clgimage} alt='' className='clgpic'></img>
        </div>
    );
}
export default Clgimage;